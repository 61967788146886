import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@fluentui/react-northstar'
import classNames from 'classnames'
import useFaceMesh from 'hooks/useFaceMesh'
import useRPPG from 'hooks/useRPPG'
import useNotification from 'hooks/useNotification'
import {
  LoadingScreen,
  CheckFps,
  ImageQuality,
  Notification,
  Info,
  TextMessage,
  ProgressType,
} from 'tabs/CaptureTab/Components'
import {
  NOTIFICATION_FACE_ORIENT_WARNING,
  NOTIFICATION_FACE_SIZE_WARNING,
  NOTIFICATION_INTERFERENCE_WARNING,
  NOTIFICATION_NO_FACE_DETECTED,
} from 'helpers/notification'
import './Capture.scss'
import { ERROR_NOT_SUPPORTED, ERROR_SOCKET_CONNECTION } from 'helpers/error'
// import { getUserApi } from 'services/AuthService'
import { useAuth } from 'context/useAuth'


export function Capture() {
  const [userId] = useState<string | null >('')
  const { logout } = useAuth()
  useEffect(()=>{
    
    const getNewId = async () =>{
      
      // const user = await localStorage.getItem('token')
      // if(user){
      //   //const getUserId = await getUserApi(user)
      //   //setUserId(getUserId[0].user_id)
      // }
    }
    getNewId()
    
  },[])

  const videoElement = useRef<HTMLVideoElement>(null)
  const canvasElement = useRef<HTMLCanvasElement>(null)
  const history = useHistory()

  const [size, setSize] = useState<{width: number, height: number}>(
    { width: 0, height: 0 }
  )

  // this hook set error message and clear also before show new message
  const {
    message,
    addNotification,
    clearAllNotifications,
  } = useNotification()

  const processingFaceMesh = useRef(false)

  const [useFrontCamera] = useState(true)

  const onUnsupportedDeviceCb = () => {
    stopHandler()
    history.push('/capture/error', ERROR_NOT_SUPPORTED)
  }

  // after geting result of user redirect to result page
  const onCalculationEndedCb = () => {
    stopHandler()
    closeCamera() //after completion the task stoping camera recording
    cameraInstance?.stop() 
    console.log(rppgData)
    history.push('/capture/results', { // adding user result into the useLocation
      rppgData,
      isAllDataCalculated,
      getUserId:userId,
    })
  }

  // checking face detected
  const onFaceDetectionChange = (faceDetected: boolean) => {
    if (processing && !faceDetected) {
      addNotification(NOTIFICATION_NO_FACE_DETECTED)
    }
  }

  // checking bad reading or bad conditions
  const onInterferenceWarningCb = () => {
    addNotification(NOTIFICATION_INTERFERENCE_WARNING)
    setTimeout(() => {
      stopHandler()
      history.push('/capture/bad-conditions')
    }, 3000)
  }

  // checking user straight ahead or not
  const onFaceOrientWarningCb = () =>
    processing && addNotification(NOTIFICATION_FACE_ORIENT_WARNING)

  // checking user far from camera or not
  const onFaceSizeWarningCb = () =>
    processing && addNotification(NOTIFICATION_FACE_SIZE_WARNING)

  // Facemesh init
  const {
    cameraInstance,
  } = useFaceMesh({
    videoElement,
    canvasElement,
    processing: processingFaceMesh,
    onFaceDetectionChange,
  })

  // Rppg init
  const {
    rppgData,
    ready,
    error,
    rppgInstance,
    isAllDataCalculated,
    imageQualityFlags,
    progressType,
    processing,
    checkFps,
    start,
    stop,
    closeCamera,
  } = useRPPG({
    videoElement,
    onUnsupportedDeviceCb,
    onAllDataCalculatedCb: onCalculationEndedCb,
    onCalculationEndedCb,
    onInterferenceWarningCb,
    onFaceOrientWarningCb,
    onFaceSizeWarningCb,
  })

  //rppg Socket Config 
  useEffect(() => {
    if (!rppgInstance || !ready || error) {
      return
    }
    console.log('useEffect - rppg - initialized')
    // @ts-ignore
    const { width, height } = rppgInstance.rppgCamera
    setSize({ width, height })
  }, [ready, error, rppgInstance])

  // checking recording start or not
  useEffect(() => {
    processingFaceMesh.current = processing
  }, [processing])

  useEffect(() => {
    if (error) {
      history.push('/capture/error', ERROR_SOCKET_CONNECTION)
    }
  }, [error, history])

  const startHandler = () => {
    // this function start camera recording
    start()
  }

  const stopHandler = () => {
    clearAllNotifications() // this function clean old message
    stop() // this funtion stop camera recording
  }

  // start recording function
  const startButtonHandler = () => {
    if (processing) {
      stopHandler()
    } else {
      startHandler()
    }
  }

  return (
    <>
    <section className="capture-section">
      <div className='overlap_box'></div>
    <div className="measurement-container">
    <button className="btn-logout" onClick={logout}> <img src={require('assets/images/logout.svg').default} alt="icon-result" /></button>
      <div className="measurement-wrapper">
      {!ready && !error && <LoadingScreen />}
        <div className={`video-container ${ready ? 'ready-class' : 'not-ready-class'}`}>
          <div className="video-wrapper">
            <video
              ref={videoElement}
              autoPlay
              playsInline
              muted
              className={classNames({
                video: true,
                horizontal: size.width > size.height,
                vertical: size.width <= size.height,
                invert: useFrontCamera,
              })}>
            </video>
            <canvas
              ref={canvasElement}
              className="canvas"
            ></canvas>
          </div>
        </div>
        <div className='video-wrapper-content'>{processing && <ImageQuality imageQualityFlags={imageQualityFlags} />}</div>

        <Button
          className="custom-button"
          primary
          content={processing ? 'Stop' : 'Start'} 
          disabled={!ready}
          loading={!ready}
          onClick={startButtonHandler}
        />

        <div className="start-block-wrapper">
        
          {message ? (
            <Notification message={message} />
          ) : (
            <>
              <TextMessage progressType={progressType} />

              {checkFps && <CheckFps />}
            </>
          )}

          {processing && progressType !== ProgressType.CALIBRATING && (
            <Info rppgData={rppgData} />
          )}

        </div>

      </div>
    </div>
    </section>
    </>
  )
}