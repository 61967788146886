import React, { useEffect, useState } from 'react'
//import OTPInput from '../Otp/otp'
import './verify.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'context/useAuth'



const VerifyUser = () => {
    const {verification, loginUser} = useAuth()
    const location = useLocation()
    const history = useHistory()
    const getNumber:any = location.state
    const [getOtp, setOtp] = useState<string>()
    //const [showDiv, setShowDiv] = useState<boolean>(false)

    useEffect(() => {
        const timer = setTimeout(() => {
          //setShowDiv(true)
        }, 10000) // 10000 milliseconds = 10 seconds
    
        return () => clearTimeout(timer)
      }, [])
    

    const confirmOtp = async () =>{
        if(!getNumber.mobile){
            history.push({ pathname: '/login' })
        }

        if(!getOtp){
            return
        }

        const number = getNumber.mobile.replace('+', '')
        const getData:any= await verification(number, getOtp)
        if(getData?.status === 'success'){
            history.push({ pathname: '/capture' })
        }

        if(getData?.status === 'fail'){
            console.log(getData.message)
        }
    }

    const resetCode= async () =>{
        await loginUser(getNumber.countreyCode, getNumber.number)
    }

    return (
        <>
           <div className='overlap_box'></div>
        <section className='verify_section'>
            <div className='login_section'>
            <div className="login_inner_section">
                <div className='header_section'>
                    {/* <div className='logo'><img src="https://staging.tb.helfie.ai/assets/images/hf-logo-black.svg" alt="" /></div> */}
                    <div className='logo'><img src={require('assets/images/hf-logo-black.svg').default} alt="icon-note" /></div>
                    <div className="otp_title">Enter your <span> secure code</span></div>
                    <div className='resent_otp'>
                        We've sent a code to <span className='resent_number' onClick={resetCode}>{getNumber.mobile} <span>Resend code</span></span>
                </div>
                </div>
            <div className='opt_title'>Secure code</div>
            {/* <OTPInput length={6} onComplete={handleSubmit} /> */}
            <input type="text" className='secure_code_input' onChange={(e)=>{
                setOtp(e.target.value)
            }} />
            <button className='confirm_otp_button' onClick={confirmOtp}>Confirm</button>
            </div>
            </div>
        </section>
        </>
    )
}


export default VerifyUser