import { UserProfile } from 'model/usertype'
import React, { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { loginAPI, saveAssessmentApi, verifyOtp } from 'services/AuthService'

type UserContextType = {
    user: UserProfile | null
    isAuthenticated: boolean
    token: string | null
    loginUser: (countreyCode:string, mobile: string) => void
    verification: (mobile:string, code: string) => void
    logout: () => void
    isLoggedIn: () => boolean
    saveAssessment: (user_id: string, heartRate: number, respRate: number, hrv: number, spo: number, stress: string, systolicBp: number, diastolicBp:number) => void
}

type Props = { children: React.ReactNode}

const UserContext = createContext<UserContextType>({} as UserContextType)

export const UserProvider = ({children}:Props) =>{
    const navigate = useHistory()
    const [token, setToken] = useState<string | null>(null)
    const [user, setUser] = useState<UserProfile | null>(null)
    const [isReady, setIsReady] = useState(false)
    const [isAuthenticated, setisAuthenticated] = useState(false)

    useEffect(()=>{

            const user = localStorage.getItem('user')
            const token = localStorage.getItem('token')
           
            if(user && token){
                setUser(JSON.parse(user))
                setToken(token)
                setisAuthenticated(true)
            }

         
        setIsReady(true)
    },[])

    useEffect(()=>{
        const getData = async () => {
            const user = await localStorage.getItem('token')
            if(user){
                setisAuthenticated(true)
            }
        }
        getData()
    },[user])

    const loginUser = async (countreyCode: string, mobile:string) =>{
     const response =  await loginAPI(countreyCode, mobile)
     return response
    }

    const verification = async (mobile: string, code: string) =>{
        const response = await verifyOtp(mobile, code)
        if(response.status === 'success'){
            localStorage.setItem('token', response?.access_token)
            // const userObj = {
            //     name: response?.data[0].name,
            //     email: response?.data[0].email,
            //     mobile: response?.data[0].mobile
            // }
            //localStorage.setItem('user', JSON.stringify(userObj))
            setToken(response?.access_token)
            setisAuthenticated(true)
        }
        return response
    }

    const saveAssessment =  async (user_id: string, heartRate: number, respRate: number, hrv: number, spo: number, stress: string, systolicBp: number, diastolicBp:number) =>{
        const response = await saveAssessmentApi(user_id, heartRate, respRate, hrv, spo, stress, systolicBp, diastolicBp)
        return response
    }

    const isLoggedIn = () =>{        
         return !!user
    }

    const logout = () =>{
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        setUser(null)
        setToken('')
        setisAuthenticated(false)
        navigate.push('/login')
        // window.location.reload();
        
    }

    return (
        <UserContext.Provider value={{loginUser, user, token, logout, isLoggedIn, verification, saveAssessment, isAuthenticated}}>
            {isReady ? children : null}
        </UserContext.Provider>
    )
}

export const useAuth = () => React.useContext(UserContext)