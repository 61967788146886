import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from 'context/useAuth'

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => 
  {
    const { isAuthenticated } = useAuth()
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
        }
      />
    )
  }
export default ProtectedRoute