const api = 'https://api.dev.helfie.ai/'

export const loginAPI = async (countreyCode: string, mobile: string) => {
  interface ParamsType {
    to: string;
  }
  interface RequestOptions {
    method: string;
    headers: Headers;
    body?: string;
    redirect?: RequestRedirect;
    // Add other properties as needed
  }
  
  try {
    const params: ParamsType = { to: `${countreyCode}${mobile}` }
    const url: string = `${api}iam/api/auth/send_otp` as string

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions: RequestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(params),
      redirect: 'follow',
    }
    const response = await fetch(url, requestOptions)
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
  }
}

export const verifyOtp = async (mobile: string, code: string) => {
    interface Params {
        to: string;
        code: string;
    }
    interface RequestOptions {
        method: string;
        headers: Headers;
        body?: string;
        redirect?: RequestRedirect;
        // Add other properties as needed
      }
  try {
    const accessToken: string = localStorage.getItem('access_token')||''
    const params: Params = { to: `+${mobile}`, code: code }
    const url: string = `${api}iam/api/auth/verify_otp` as string

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + accessToken)

    // const urlencoded = new URLSearchParams()
    // if (params) {
    //     Object.keys(params).forEach((key) => urlencoded.append(key, params[key]))
    // }
    const requestOptions: RequestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(params),
      redirect: 'follow',
    }
    const response = await fetch(url, requestOptions)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('opt auth error')
  }
}

export const saveAssessmentApi = async (
  userId: string,
  heartRate: number,
  respRate: number,
  hrv: number,
  spo: number,
  stress: string,
  systolicBp: number,
  diastolicBp: number
) => {
  try {
    interface VitalsParams {
        user_id: string; // Or number, based on your actual userId type
        assessment_type: string;
        data: {
            heart_rate: number;
            resp_rate: number;
            hrv: number;
            spo2: number;
            stress: string;
            systolic_bp: number;
            diastolic_bp: number;
        };
    }

    interface RequestOptions {
        method: string;
        headers: Headers;
        body: string;
        redirect: RequestRedirect;
    }
    

    const url: string = `${api}vitals/api/save_assessment` as string
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    //myHeaders.append('Authorization', 'Bearer ' + accessToken)
    myHeaders.append('api-key', '025d17da-724f-4f5b-9da3-e14fbdf0f31a')
   
    const params: VitalsParams = {
      user_id: userId,
      assessment_type: 'vitals',
      data: {
        heart_rate: heartRate,
        resp_rate: respRate,
        hrv: hrv,
        spo2: spo,
        stress: stress,
        systolic_bp: systolicBp,
        diastolic_bp: diastolicBp,
      },
    }
    const requestOptions: RequestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(params),
      redirect: 'follow',
    }
    const response = await fetch(url, requestOptions)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('opt auth error')
  }
}

export const getUserApi = async (token: string) => {
  try{

    interface RequestOptions {
        method: string;
        headers: Headers;
        redirect: RequestRedirect;
    }

    const url: string = `${api}iam/api/user` as string
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)
    myHeaders.append('api-key', '025d17da-724f-4f5b-9da3-e14fbdf0f31a')
   
    const requestOptions: RequestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    const response = await fetch(url, requestOptions)
    const data = await response.json()
    return data

  } catch (error){
    console.log('user not found')
  }
}
