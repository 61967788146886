import React, { useEffect, useState, useRef } from 'react'
import './login.scss'
import { countries } from 'consts/constants'
import {useHistory } from 'react-router-dom'
import { useAuth } from 'context/useAuth'
import Spinner from 'consts/sppiner'

export const Login = () => {
  
  const {loginUser, isAuthenticated} = useAuth()
  const [open, SetOpen] = useState<boolean>(false)
  const [filterCode, setFilterCode] = useState(countries)
  const [countreyCode, setCountreyCode] = useState('')
  const dropdownlist_ref = useRef<HTMLDivElement>(null)
  const overlaplist_ref = useRef<HTMLDivElement>(null)
  const overlapinput_ref = useRef<HTMLDivElement>(null)
  const [mobile, setMobile] = useState<string | null>('')
  const [spinner, setSpinner] = useState<boolean>(false)
  const history = useHistory()
  
  const [error, setError] = useState('')

  useEffect(()=>{
    if(isAuthenticated){
      history.replace('/capture')
    }
  },[isAuthenticated, history])

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://ipapi.co/${data.ip}/json/`)
          .then((response) => response.json())
          .then((data) => {
            setCountreyCode(data.country_calling_code)
          }
          )
          .catch(() => console.log('')
          )
      })
      .catch(() => console.log(''))
  }, [])


  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://ipapi.co/${data.ip}/json/`)
          .then((response) => response.json())
          .then((data) => {
            setCountreyCode(data.country_calling_code)
          }
          )
          .catch(() => console.log('')
          )
      })
      .catch(() => console.log(''))
  }, [])

  const changeOpen = () =>{
    SetOpen((open)=> !open)
  }
  
  const changeCode = (values:string) => {
    setCountreyCode(values)
    const input1 = document.getElementById('filterCountrey') as HTMLInputElement
    if(input1){
      input1.value = ''
    }
    SetOpen(false)
  }

  const getOTPValue = async ()=>{
    if(!mobile){
        setError('Phone number is invalid')
        return
    }
    setSpinner(true)
   const getLogin:any = await loginUser(countreyCode, mobile)
   
   if(getLogin?.status === 'success'){
    history.push({ pathname: '/verification', state: {mobile:`${countreyCode}${mobile}`, number:mobile, countreyCode: countreyCode} })
   }
    
  }

  useEffect(()=>{
    const handler = (event:MouseEvent) =>{
      if(dropdownlist_ref.current && dropdownlist_ref.current.contains(event.target as Node)){
        SetOpen(false)
      }
    }

    document.addEventListener('click', handler)
    return () =>{
      document.removeEventListener('click', handler)
    }
  }, [])

  useEffect(()=>{
    const handler = (event:MouseEvent) =>{
      if(overlaplist_ref.current && overlaplist_ref.current.contains(event.target as Node)){
        SetOpen(false)
      }
    }

    document.addEventListener('click', handler)
    return () =>{
      document.removeEventListener('click', handler)
    }
  }, [])

  useEffect(()=>{
    const handler = (event:MouseEvent) =>{
      if(overlapinput_ref.current && overlapinput_ref.current.contains(event.target as Node)){
        SetOpen(false)
      }
    }

    document.addEventListener('click', handler)
    return () =>{
      document.removeEventListener('click', handler)
    }
  }, [])

  

  return (
    <>
    
    <div className='overlap_box' ref={overlaplist_ref}></div>
    <div className="login_section">
      <div className="login_inner_section">
        <div className='header_section' ref={dropdownlist_ref}>
            {/* <div className='logo'><img src="https://staging.tb.helfie.ai/assets/images/hf-logo-black.svg" alt="" /></div> */}
            <div className='logo'><img src={require('assets/images/hf-logo-black.svg').default} alt="icon-note" /></div>
            <div className="login_title" style={{fontSize:'30px'}}>Hello</div>
            <div className="login_detail">Streamlined Access: Securely log in with your mobile number for a hassle-free experience!</div>
        </div>
        <div className='select_country_box'>
            <div className='select_countrey'>
                <input type="text" className='country_code_input' id='filterCountrey' placeholder={countreyCode} onClick={changeOpen} onChange={(e)=>{
                  const new_cou = countries.filter(countrey => {
                    //let filterCode:any = [];
                    if(countrey.dial_code.includes(e.target.value)){ 
                      //console.log(countrey.dial_code);
                      //filterCode = [...filterCode, countrey]
                      return true
                    }
                    return false
                  })
                  setFilterCode(new_cou)
                }}/>
                <div className={`dropdown_list ${open ? 'view_list':'hide_list'}`}>
                {filterCode.map((item, index) => {
                        return (
                        <div className="countrey_name" key={index} onClick={()=>changeCode(item.dial_code)}>
                            {item.dial_code}
                            &nbsp; {item.name}
                        </div>
                        )
                    })}
                </div>
            </div>
                <input type="text" className="mobile_input" onClick={() => SetOpen(false)} onChange={(e) =>{
                    setMobile(e.target.value)
                    if(e.target.value.length < 9 || e.target.value.length > 13){
                        setError('Phone number is invalid')
                    }else{
                        setError('')
                    }
                }} style={{height:'50px', width:'100%'}}/>
        </div>
        <div className='error'>{error}</div>
        <div className='login_short_detail'>We will never spam you or share your mobile number or any health related data with anyone</div>
        <button className='send_otp_code_button' onClick={getOTPValue}>
          Send Code {spinner && (<Spinner width={20} height={20} />)}
        </button>
    </div>
    </div>
    </>
  )
}